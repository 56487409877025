// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-associations-js": () => import("./../../../src/pages/associations.js" /* webpackChunkName: "component---src-pages-associations-js" */),
  "component---src-pages-complementary-js": () => import("./../../../src/pages/complementary.js" /* webpackChunkName: "component---src-pages-complementary-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-postgraduate-js": () => import("./../../../src/pages/postgraduate.js" /* webpackChunkName: "component---src-pages-postgraduate-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-studies-js": () => import("./../../../src/pages/studies.js" /* webpackChunkName: "component---src-pages-studies-js" */),
  "component---src-pages-undergraduate-js": () => import("./../../../src/pages/undergraduate.js" /* webpackChunkName: "component---src-pages-undergraduate-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

